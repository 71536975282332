<template>
  <div>
    <vx-card>
      <h3 class="text-info">{{ this.$t('Crear Nuevo Banner') }}</h3>
      <div class="vx-row mt-5">
        <div class="vx-col sm:w-1/4 w-full">
          <label for="bannerTypeId">{{ this.$t('Categoria de Banner:') }}</label>
          <vs-select
            placeholder="Seleccione una categoria"
            v-model="banner.banner_category_id"
            id="bannerTypeId"
            class="w-full"
          >
            <vs-select-item
              v-bind:key="item.id"
              :value="item.id"
              :text="item.name"
              v-for="item in bannerCategory"
            />
          </vs-select>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label for="bannerTypeId">{{ this.$t('Tipo de Banner:') }}</label>
          <vs-select
            placeholder="Seleccione un tipo"
            v-model="banner.type"
            id="bannerTypeId"
            class="w-full"
          >
            <vs-select-item
              v-bind:key="item.id"
              :value="item.id"
              :text="item.name"
              v-for="item in bannerTypes"
            />
          </vs-select>
        </div>

        <div class="vx-col sm:w-1/2 w-full">
          <!-- nombre -->
          <label for="name">{{ this.$t('Nombre del Banner:') }}</label>
          <vs-input v-model="banner.name" id="name" class="w-full" />
        </div>

        <div class="vx-col sm:w-1/2 mt-5">
          <div class="grid grid-cols-2 gap-6">
            <!-- fecha de inicio -->
            <div class="px-2">
              <label for="initDate">{{ this.$t('Fecha de Inicio:') }}</label><br />
              <sub class="text-grey italic">YYYY-MM-DD</sub>
              <flat-pickr
                v-model="banner.start_date_prog"
                id="initDate"
                class="w-full"
              />
            </div>

            <!-- hora de inicio -->
            <div class="px-2">
              <label for="initHour">{{ this.$t('Hora de Inicio:') }}</label><br />
              <sub class="text-grey italic">HH:MM:SS</sub>
              <flat-pickr
                :config="configTimepicker"
                v-model="banner.start_hour"
                id="initHour"
                class="w-full"
              />
            </div>
          </div>
        </div>

        <div class="vx-col sm:w-1/2 mt-5">
          <div class="grid grid-cols-2 gap-6">
            <!-- fecha final -->
            <div class="px-2">
              <label for="endDate">{{ this.$t('Fecha final:') }}</label><br />
              <sub class="text-grey italic">YYYY-MM-DD</sub>
              <flat-pickr
                v-model="banner.end_date_prog"
                id="endDate"
                class="w-full"
              />
            </div>

            <!-- hora final -->
            <div class="px-2">
              <label for="endHour">{{ this.$t('Hora final:') }}</label><br />
              <sub class="text-grey italic">HH:MM:SS</sub>
              <flat-pickr
                :config="configTimepicker"
                v-model="banner.end_hour"
                id="endHour"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- selección de tiendas -->
    <!-- <vx-card class="mt-10" v-if="banner.type === 2">
      <h4 class="text-info">Tiendas</h4>

      <div class="flex space-x-4 items-center">
        <label for="bannerTypeId">Seleccione una tienda:</label>
        <v-select
          :options="stores"
          label="name"
          :reduce="(store) => store.id"
          v-model="banner.shops"
          class="w-1/2"
          multiple
          placeholder="seleccione una tienda"
          id="bannerTypeId"
        >
          <template slot="list-header">
            <vs-checkbox v-model="select_all_stores" class="mx-10 my-5"
              >Seleccionar todas las tiendas</vs-checkbox
            >
          </template>

          <template slot="option" slot-scope="item">
            <feather-icon
              v-if="isStoreSelected(item.id)"
              icon="CheckIcon"
              class="align-middle"
            />
            <span class="inline-block align-middle">{{ item.name }}</span>
          </template>

          <template slot="selected-option" slot-scope="item">
            <span class="inline-block align-middle">{{ item.name }}</span>
          </template>
        </v-select>
      </div>
    </vx-card> -->

    <!-- selcción de categoría -->
    <vx-card class="mt-10" v-if="banner.type === 1">
      <h4 class="text-info">{{ this.$t('Categoría') }}</h4>

      <div class="vx-row mt-5">
        <div class="vx-col sm:w-1/2 w-full">
          <div class="flex space-x-4 items-center">
            <label for="bannerCatId">{{ this.$t('Seleccione la categoría:') }}</label>
            <v-select
              :options="categories"
              label="title"
              :reduce="(cat) => cat.id"
              v-model="banner.redirect_id"
              class="sm:w-1/2 w-full"
              placeholder="categoría"
            >
              <template slot="option" slot-scope="item">
                <span class="inline-block align-middle">{{ item.title }}</span>
              </template>

              <template slot="selected-option" slot-scope="item">
                <span class="inline-block align-middle">{{ item.title }}</span>
              </template>
            </v-select>
          </div>
        </div>

        <!-- <div class="vx-col w-1/2">
          <div class="flex space-x-4 items-center">
            <label for="bannerTypeIdCat">Seleccione una tienda:</label>
            <v-select
              :options="stores"
              label="name"
              :reduce="(store) => store.id"
              v-model="banner.shops"
              class="w-1/2"
              multiple
              placeholder="seleccione una tienda"
              id="bannerTypeIdCat"
            >
              <template slot="list-header">
                <vs-checkbox v-model="select_all_stores" class="mx-10 my-5"
                  >Seleccionar todas las tiendas</vs-checkbox
                >
              </template>

              <template slot="option" slot-scope="item">
                <feather-icon
                  v-if="isStoreSelected(item.id)"
                  icon="CheckIcon"
                  class="align-middle"
                />
                <span class="inline-block align-middle">{{ item.name }}</span>
              </template>

              <template slot="selected-option" slot-scope="item">
                <span class="inline-block align-middle">{{ item.name }}</span>
              </template>
            </v-select>
          </div>
        </div> -->
      </div>
    </vx-card>

    <!-- selcción de producto -->
    <vx-card class="mt-10" v-if="banner.type === 4">
      <h4 class="text-info">{{ this.$t('Producto') }}</h4>

      <div class="vx-row mt-5">
        <!-- <div class="vx-col w-1/2">
          <div class="flex space-x-4 items-center">
            <label for="bannerTypeIdProd">Seleccione una tienda:</label>
            <v-select
              :options="stores"
              label="name"
              :reduce="(store) => store.id"
              v-model="banner.shops"
              class="w-1/2"
              multiple
              placeholder="seleccione una tienda"
              id="bannerTypeIdProd"
            >
              <template slot="list-header">
                <vs-checkbox v-model="select_all_stores" class="mx-10 my-5"
                  >Seleccionar todas las tiendas</vs-checkbox
                >
              </template>

              <template slot="option" slot-scope="item">
                <feather-icon
                  v-if="isStoreSelected(item.id)"
                  icon="CheckIcon"
                  class="align-middle"
                />
                <span class="inline-block align-middle">{{ item.name }}</span>
              </template>

              <template slot="selected-option" slot-scope="item">
                <span class="inline-block align-middle">{{ item.name }}</span>
              </template>
            </v-select>
          </div>
        </div> -->

        <div class="vx-col sm:w-1/2 w-full">
          <div class="flex space-x-4 items-center">
            <label for="bannerCatId">{{ this.$t('Seleccione un producto:') }}</label>
            <v-select
              :options="products"
              label="title"
              :reduce="(prod) => prod.id"
              v-model="banner.redirect_id"
              class="sm:w-1/2 w-full"
              placeholder="producto"
              @search="getProducts"
            >
              <template slot="option" slot-scope="item">
                <span class="inline-block align-middle">{{ item.title }}</span>
              </template>

              <template slot="selected-option" slot-scope="item">
                <span class="inline-block align-middle">{{ item.title }}</span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- informativo -->
    <vx-card class="mt-10" v-if="banner.type === 3">
      <h4 class="mb-5 text-info">{{ this.$t('Informativo') }}</h4>

      <div class="vx-row mt-5">
        <div class="vx-col sm:w-1/2 w-full">
          <label for="url">{{ this.$t('Ingrese una URL:') }}</label>
          <vs-input v-model="banner.link" id="url" class="w-full" />
        </div>

        <!-- <div class="vx-col w-1/2">
          <label for="bannerTypeIdProd">Seleccione una tienda:</label>
          <v-select
            :options="stores"
            label="name"
            :reduce="(store) => store.id"
            v-model="banner.shops"
            class="w-full"
            multiple
            placeholder="seleccione una tienda"
            id="bannerTypeIdProd"
          >
            <template slot="list-header">
              <vs-checkbox v-model="select_all_stores" class="mx-10 my-5"
                >Seleccionar todas las tiendas</vs-checkbox
              >
            </template>

            <template slot="option" slot-scope="item">
              <feather-icon
                v-if="isStoreSelected(item.id)"
                icon="CheckIcon"
                class="align-middle"
              />
              <span class="inline-block align-middle">{{ item.name }}</span>
            </template>

            <template slot="selected-option" slot-scope="item">
              <span class="inline-block align-middle">{{ item.name }}</span>
            </template>
          </v-select>
        </div> -->
      </div>
    </vx-card>

    <!-- imagen -->
    <input
      type="file"
      name="fields[assetsFieldHandle][]"
      id="assetsFieldHandle"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      ref="file"
      accept="image/png, image/jpeg"
      @change="onChange()"
    />

    <div
      v-if="!image.file"
      class="border-2 border-dashed text-center pt-5 pb-8 mt-10 cursor-pointer"
      @click="clickFileButton()"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <img
        :src="require(`@/assets/icons/UploadFileIcon.svg`)"
        width="32px"
        class="inline-block align-middle"
      /><br /><br />
      <span class="text-gray">{{ this.$t('Suelta tu imagen aquí, ó') }}</span>&nbsp;
      <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small"
        >{{ this.$t('Busca Archivo') }}</vs-button
      >
    </div>

    <div v-if="!image.file" class="vx-row mt-2">
      <div class="vx-col sm:w-1/2">
        <span class="text-gray">{{ this.$t('Formatos Soportados: JPG, PNG') }}</span>
      </div>

      <div class="vx-col sm:w-1/2">
        <span class="text-gray float-right">{{ this.$t('Tamaño Máximo: 5Mb') }}</span>
      </div>
    </div>

    <vx-card v-else class="mt-10">
      <div class="vx-row">
        <div class="vx-col w-auto">
          <img
            :src="require(`@/assets/icons/JpgFileIcon.svg`)"
            width="60px"
            class="inline-block align-middle"
          /><br /><br />
        </div>

        <div class="vx-col w-11/12">
          <feather-icon
            icon="XIcon"
            class="float-right cursor-pointer"
            @click="image.file = null"
          />
          <h4>{{ image.file.name }}</h4>
          <span>Subido {{ image.progress }}%</span>
          <vs-progress
            :height="12"
            :percent="image.progress"
            :color="image.error ? 'danger' : 'success'"
          ></vs-progress>
        </div>
      </div>
    </vx-card>
    <!-- fin imagen -->

    <!-- seccion guardar -->
    <div class="flex justify-around mt-10 mb-10">
      <button
        class="vs-component vs-button rounded-full vs-button-filled bg-primary text-white"
        @click="attempSaveBanner()"
      >
        {{ this.$t('Guardar') }}
      </button>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Utils from "../../../../plugins/utils";

export default {
  data() {
    return {
      configTimepicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
      },
      utils: Utils,
      image: {
        progress: 0,
        error: "",
        file: null,
      },
      validFormats: ["jpg", "jpeg", "png"],
      select_all_stores: false,
      products: [],
      shop: 0,
      bannerCategory: [{id: 3, name: 'Informação'}, {id: 1, name: 'Promoção'}]
    };
  },

  async created() {
    this.shop = 727
    this.setCurrDate();
    this.setDateEnd();

    this.$vs.loading();
    await this.$store.dispatch("stores/getAllStores");
    await this.$store.dispatch("categories/getAllCategories");
    await this.getProducts();
    await this.$store.dispatch("promotions/getAllBannerTypes");
    this.$vs.loading.close();
  },

  watch: {
    /* select_all_stores(val) {
      this.toggleAllstores(val);
    }, */
  },

  methods: {
    async getProducts(name = "") {
      const payload = {
        page: 1,
        page_size: 500,
        name,
      };
      const res = await this.$store.dispatch(
        "products/getAllProducts",
        payload
      );
      const products = res.results;
      this.products = products;
    },
    setCurrDate() {
      const now = new Date();
      const currDateStr = this.utils.dateToStr(now);

      this.banner.start_date_prog = currDateStr.substring(0, 10);
      this.banner.start_hour = currDateStr.substring(11, 19);
    },
    setDateEnd() {
      const now = new Date();
      const afterMonthTm = now.setMonth(now.getMonth() + 1);
      const afterMonth = new Date(afterMonthTm);
      const afterMonthStr = this.utils.dateToStr(afterMonth);

      this.banner.end_date_prog = afterMonthStr.substring(0, 10);
      this.banner.end_hour = afterMonthStr.substring(11, 19);
    },
    onChange() {
      const filename = this.$refs.file.files[0].name;
      const filesize = this.$refs.file.files[0].size;
      const filesizeMb = Math.floor(filesize / 1000000);
      const ext = filename.substr(filename.lastIndexOf(".") + 1).toLowerCase();

      const isValidFormat = this.validFormats.filter(
        (format) => format === ext
      );

      if (filesizeMb > 2) {
        this.$vs.notify({
          title: `La imagen tiene un tamaño de ${filesizeMb} Mb`,
          text: `El límite de tamaño es de 2Mb`,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          time: 7000,
        });
        return;
      }

      if (isValidFormat.length === 0) {
        this.$vs.notify({
          title: "Formato Incorrecto",
          text: "Cargue archivos .jpg ó .png solamente",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return;
      }

      this.image.file = this.$refs.file.files[0];
      // this.$store.dispatch( 'product/loadProductImage', this.image );
    },
    dragover(ev) {
      ev.preventDefault();

      if (!ev.currentTarget.classList.contains("bg-primary")) {
        ev.currentTarget.classList.remove("bg-gray-100");
        ev.currentTarget.classList.add("bg-primary");
      }
    },
    dragleave(ev) {
      ev.currentTarget.classList.remove("bg-primary");
    },
    drop(ev) {
      ev.preventDefault();

      this.$refs.file.files = ev.dataTransfer.files;
      this.onChange();
      ev.currentTarget.classList.remove("bg-primary");
    },
    clickFileButton() {
      this.$refs.file.click();
    },
    attempSaveBanner() {
      let shops = [this.shop];
      this.banner.shops = shops
      
      const isValidData = this.validateData();

      if (!isValidData) return;

      this.saveImage();
    },
    saveImage() {
      this.$vs.loading();
      this.$store
        .dispatch("firebase/uploadImage", this.image)
        .then((res) => {
          this.$vs.loading.close();
          this.banner.image = res;
          this.saveBanner();
        })
        .catch(() => {
          this.$vs.loading.close();
          this.setToastMessage(
            this.$t("Ocurrió un error al cargar la imagen. Intente unos minutos más tarde")
          );
        });
    },
    saveBanner() {
      this.$vs.loading();
      this.$store.dispatch("promotions/saveBanner", this.banner).then((res) => {
        this.$vs.loading.close();
        if (!res) {
          this.setToastMessage(
            this.$t("Ocurrió un error al guardar el banner. Intente unos minutos más tarde")
          );
          return;
        }

        this.image.file = null;
        this.$router.push({ path: "/app/promotions/banners" });
      });
    },
    validateData() {
      const isValidType = this.banner.type;
      const isValidName = this.banner.name;
      const isValidImage = this.image.file;

      if (!isValidType || !isValidName || !isValidImage) {
        this.setToastMessage(
          this.$t("Seleccione un tipo de banner, un nombre de banner o cargue una imagen")
        );
        return false;
      }

      // Validación de tipos
      if (this.banner.type === 2) {
        if (this.banner.shops.length === 0) {
          this.setToastMessage(this.$t("Seleccione al menos una tienda"));
          return false;
        }
      } else if (this.banner.type === 1) {
        if (this.banner.shops.length === 0 || this.banner.redirect_id === 0) {
          this.setToastMessage(
            this.$t("Seleccione al menos una tienda o una categoría")
          );
          return false;
        }
      } else if (this.banner.type === 3) {
        if (this.banner.shops.length === 0) {
          this.setToastMessage(this.$t("Seleccione al menos una tienda"));
          return false;
        }
      } else if (this.banner.type === 4) {
        if (this.banner.shops.length === 0 || this.banner.redirect_id === 0) {
          this.setToastMessage(this.$t("Seleccione al menos una tienda o un producto"));
          return false;
        }
      }

      return true;
    },
    setToastMessage(text = "") {
      this.$vs.notify({
        text,
        title: this.$t("Ingrese los datos solicitados"),
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
        time: 7000,
      });
    },
    isStoreSelected(store_id = 0) {
      const store = this.banner.shops.find((s_id) => s_id === store_id);
      return !!store;
    },
  },

  computed: {
    banner: {
      get() {
        const store = this.$store;
        const banner = store.state.promotions.banner;
        let bannerProxy = new Proxy(banner, {
          set(obj, key, value) {
            store.commit("promotions/SET_BANNER", {
              ...obj,
              [key]: value,
            });
            return true;
          },
        });
        return bannerProxy;
      },
      set(val) {
        this.$store.commit("promotions/SET_BANNER", val);
      },
    },
    stores() {
      return this.$store.state.stores.stores;
    },
    categories() {
      return this.$store.state.categories.categories;
    },
    // products() { return this.$store.state.products.products; },
    bannerTypes() {
      return this.$store.state.promotions.bannerTypes;
    },
  },

  components: {
    flatPickr,
    "v-select": vSelect,
  },
};
</script>
